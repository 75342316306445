<template>
  <div class="foot">
    <div id="footerbox" class="bg2 py-5">
      <div class="copy-right">
        <div class="d-flex flex-column mb-0 text-center">
          <a class="mb-1 allright"
            ><small>{{ $t("copyright") }}</small></a
          >
          <!-- <a class="mb-2 allright" ><small>HongKong Macnn International Group Limited.</small></a> -->
          <span
            ><a
              href="https://instagram.com/macnninc/"
              class="instagram"
              target="_blank"
              rel="noopener"
              ><i class="bi bi-instagram pe-2"></i>Instagram</a
            ></span
          >
        </div>
      </div>
    </div>

    <!-- Fixed ToolBar -->
    <div class="m-top-cbbfixed">
      <a class="m-top-bg" @click="tocart"><i class="bi-cart-fill"></i></a>

      <a class="m-top-go" @click="backToTop"><i class="bi-chevron-up"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerbox",
  props: {
    backPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      interval: null,
      isMoving: false,
    };
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    tocart() {
      this.$router.push("/cart");
    },

    backToTop() {
      if (this.isMoving) return;
      const start = window.pageYOffset;
      let i = 0;
      this.isMoving = true;
      this.interval = setInterval(() => {
        const next = Math.floor(this.easeInOutQuad(10 * i, start, -start, 500));
        if (next <= this.backPosition) {
          window.scrollTo(0, this.backPosition);
          clearInterval(this.interval);
          this.isMoving = false;
        } else {
          window.scrollTo(0, next);
        }
        i++;
      }, 16.7);
    },
    easeInOutQuad(t, b, c, d) {
      if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
      return (-c / 2) * (--t * (t - 2) - 1) + b;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

@keyframes heartBeat {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  14% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  28% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  42% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
