<template>
  <div class="fixedBar">
    <div class="topFixed">
      <div class="headerbox">
        <div class="header">
          <img class="logo" src="@/assets/icon/logo.png" />
          <div class="toolsBar">
            <div class="toplink">
              <el-switch
                v-model="theme"
                size="mini"
                inline-prompt
                active-text="Dark"
                inactive-text="Light"
                active-color="#FFF"
                inactive-color="#409eff"
              />
            </div>
            <div class="toplink">
              <i
                class="bi bi-geo-alt-fill headericon"
                role="img"
                aria-label="bi bi-geo-alt-fill"
              ></i>
              <div class="linktext">{{ country_name }}</div>
            </div>
            <div class="toplink" @click="tofqa">
              <i
                class="bi-question-circle-fill headericon"
                role="img"
                aria-label="bi-question-circle-fill"
              ></i>
              <div class="linktext">FAQ</div>
            </div>
            <div class="toplink" @click="tocart">
              <i
                class="bi-cart-fill headericon"
                role="img"
                aria-label="bi-cart-fill"
              ></i>
              <div class="linktext">Cart</div>
            </div>
            <div class="toplink">
              <i
                class="bi-person-circle headericon"
                role="img"
                aria-label="bi-person-circle"
              ></i>
              <div>
                <b-dropdown
                  v-if="!token"
                  text="Login"
                  variant="info"
                  ref="dropdown"
                  class="mylogin"
                >
                  <form class="form-login mb-2">
                    <div class="mb-2 inputbox">
                      <div class="mb-1 titletext">{{ $t("user") }}</div>
                      <input
                        type="text"
                        class="form-control ajax_username"
                        name="username"
                        ref="username"
                        v-model="form.username"
                        @blur="validateInput"
                        form-verify="required|email"
                      />
                      <p class="feedback ps-2 fs-7"></p>
                    </div>
                    <div class="inputbox">
                      <div class="mb-1 titletext">{{ $t("Password") }}</div>
                      <input
                        type="password"
                        class="form-control"
                        v-model="form.password"
                        @blur="validateInput"
                        name="password"
                        ref="password"
                        form-verify="required|stringCheck"
                      />
                      <p class="feedback ps-2">
                        <a
                          class="text-secondary text-decoration-underline fs-7"
                          @click="toForget"
                        ></a>
                      </p>
                    </div>
                    <div class="d-flex flex-row inputbox mb-4">
                      <span class="pe-2 form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="remember"
                          v-model="form.remember"
                        />
                        <label class="form-check-label fs-7" for="remember">{{
                          $t("Remember Me")
                        }}</label>
                      </span>
                      <span class="pe">
                        <a class="text-primary fs-7" @click="toRegister">{{
                          $t("Open Account")
                        }}</a>
                      </span>
                    </div>
                    <div class="inputbox">
                      <span class="pe ps-0"
                        ><button
                          @click="loginSubmit"
                          size="mini"
                          type="button"
                          class="btn btn-primary"
                        >
                          {{ $t("Login") }}
                        </button></span
                      >
                    </div>
                  </form>
                </b-dropdown>
                <b-dropdown
                  v-if="token"
                  text="My Account"
                  variant="info"
                  ref="dropdown2"
                  class="myAccount mb-0"
                >
                  <b-dropdown-item @click="toOrder">My Orders</b-dropdown-item>
                  <b-dropdown-item @click="toAccount"
                    >My Account</b-dropdown-item
                  >
                  <b-dropdown-item @click="toLogout">Logout</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbox">
        <div class="navlist">
          <div class="navLink" @click="toHome">
            <i
              class="bi-house-fill navicon"
              role="img"
              aria-label="bi-person-circle"
            ></i>
            <div class="navtext">Home</div>
          </div>
          <div class="navLink" @click="toProducts">
            <i
              class="bi-box-fill navicon"
              role="img"
              aria-label="bi-person-circle"
            ></i>
            <div class="navtext">Product</div>
          </div>
          <div class="navLink" @click="toFuter">
            <i
              class="bi-calendar2-check-fill navicon"
              role="img"
              aria-label="bi-person-circle"
            ></i>
            <div class="navtext">Future</div>
          </div>
          <div class="navLink" @click="tologin">
            <i
              class="bi bi-person-square navicon"
              role="img"
              aria-label="bi-person-circle"
            ></i>
            <div class="navtext">Member</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ipInfo } from "@/api/index.js";
export default {
  name: "PcWebsiteSiteHeader",

  data() {
    return {
      secretKey: "12321312321",
      form: {
        username: "",
        password: "",
        remember: false,
      },
      fieldErrors: {
        username: "",
        password: "",
      },
      ruleField: ["username", "password"],
    };
  },
  created() {
    // 在组件创建时调用 ipInfo 请求方法获取 IP 信息
    this.getIPInfo();
  },
  computed: {
    theme: {
      //0 light 1dark
      get() {
        return this.$store.state.theme;
      },
      set(v) {
        this.$store.commit("theme", v);
      },
    },
    country_name: {
      get() {
        return localStorage.getItem("country_name");
      },
    },
    isZh: {
      get() {
        return this.$i18n.locale === "zh";
      },
    },
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
  },
  watch: {
    theme: {
      handler(v) {
        const addthemename = v === false ? "light" : "dark";
        const removethemename = v === false ? "dark" : "light";
        document.documentElement.classList.add(addthemename);
        document.documentElement.classList.remove(removethemename);
      },
    },

    itemType: {
      get() {
        return this.$store.state.itemType;
      },
      set(v) {
        this.$store.commit("setItemType", v);
      },
    },
  },
  mounted() {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    // console.log('username',username,'password',username)
    if (username) {
      this.form.username = this.decrypt(username);
    } else {
      this.form.username = "";
    }

    if (password) {
      this.form.password = this.decrypt(password);
    } else {
      this.form.password = "";
    }
  },

  methods: {
    // 获取 IP 信息并处理返回结果
    async getIPInfo() {
      try {
        if (!localStorage.getItem("country_name")) {
          const response = await ipInfo();
          const countryName = response.data;
          localStorage.setItem("country_name", countryName); // 将国家名称存储到本地存储中
        }
      } catch (error) {
        console.error("无法获取IP信息：", error);
      }
    },
    // 加密函数
    encrypt(message) {
      let encrypted = "";
      for (let i = 0; i < message.length; i++) {
        encrypted += String.fromCharCode(
          message.charCodeAt(i) ^
            this.secretKey.charCodeAt(i % this.secretKey.length)
        );
      }
      return encrypted;
    },

    // 解密函数
    decrypt(encryptedMessage) {
      return this.encrypt(encryptedMessage); // 因为是基于异或运算的简单加密，所以加密和解密过程相同
    },

    validateInput(event) {
      const field = event.target.getAttribute("name");
      let strings = event.target.getAttribute("form-verify");
      const rules = strings.split("|");

      // console.log(event, field, rules)
      for (const rule of rules) {
        if (!this.validateField(field, rule)) {
          this.fieldErrors[field] = this.$t(`verify-${rule}`);
          break;
        } else {
          delete this.fieldErrors[field];
        }
      }

      // 更新界面反馈
      // 示例：在DOM中找到对应的反馈元素并设置其内容
      const feedbackElement =
        event.target.parentNode.querySelector(".feedback");
      if (feedbackElement.classList.contains("feedback")) {
        feedbackElement.textContent = this.fieldErrors[field] || "";
        // feedbackElement
        if (this.fieldErrors[field]) {
          feedbackElement.style.color = "red";
        } else {
          feedbackElement.style.color = ""; // 恢复到默认颜色
        }
      }
    },

    validateField(field, rule) {
      // 根据rule执行相应的验证逻辑，并返回验证结果（true或false）
      // 示例：
      switch (rule) {
        case "required":
          return this.form[field] !== "";
        case "email":
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const phoneRegex = /^1[3-9]\d{9}$/; // 示例为中国手机号正则表达式
          if (this.isZh) {
            return phoneRegex.test(this.form[field]);
          } else {
            return pattern.test(this.form[field]);
          }

        case "stringCheck": // 假设这是一个自定义字符串检查规则
          return (
            typeof this.form[field] === "string" &&
            this.form[field].length > 0 &&
            this.form[field] === this.form.password
          );
      }
    },
    // 登录提交
    async loginSubmit() {
      if (this.allFieldsVerify()) {
        try {
          const userinfo = this.form;
          this.$message({
            message: this.$t("Login Success"),
            type: "success",
          });
          await this.$store.dispatch("user/login", userinfo);
          if (this.form.remember) {
            localStorage.setItem("username", this.encrypt(this.form.username));
            localStorage.setItem("password", this.encrypt(this.form.password));
          }
          this.$router.push("/");
        } catch (error) {
          console.error("登录失败：", error);
        }
      }
    },

    allFieldsVerify() {
      let pass = true;
      this.ruleField.map((item) => {
        let strings = this.$refs[item].getAttribute("form-verify");
        const rules = strings.split("|");

        // console.log('rules', rules, 'item', item)

        for (const rule of rules) {
          if (!this.validateField(item, rule)) {
            pass = false;
            this.fieldErrors[item] = this.$t(`verify-${rule}`);
            break;
          } else {
            delete this.fieldErrors[item];
          }
        }
        // 更新界面反馈
        // 示例：在DOM中找到对应的反馈元素并设置其内容
        const feedbackElement =
          this.$refs[item].parentNode.querySelector(".feedback");
        if (feedbackElement.classList.contains("feedback")) {
          feedbackElement.textContent = this.fieldErrors[item] || "";
          if (this.fieldErrors[item]) {
            feedbackElement.style.color = "red";
          } else {
            feedbackElement.style.color = ""; // 恢复到默认颜色
          }
        }
      });
      return pass;
    },
    toLogout() {
      try {
        // 清除浏览器存储的缓存
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        this.$message({
          message: this.$t("Logout Success"),
          type: "success",
        });
        // 执行退出登录逻辑
        this.logout();
      } catch (error) {
        console.error("退出登录失败：", error);
      }
    },

    toHome() {
      this.$router.push("/");
    },
    toFuter() {
      this.$router.push("/futer");
    },    
    toAccount() {
      this.$router.push("/account");
    },
    toOrder() {
      this.$router.push("/order/list");
    },
    toProducts() {
      this.$router.push("/allproducts");
    },
    tofqa() {
      this.$router.push("/faq");
    },
    tocart() {
      this.$router.push("/cart");
    },
    tologin() {
      if (this.token) {
        this.$router.push("/account");
      } else {
        this.$router.push("/login");
      }
    },
    // 退出登录
    async logout() {
      try {
        await this.$store.dispatch("user/logout");
        this.$message({
          message: this.$t("Logout Success"),
          type: "success",
        });
        this.$router.push({
          name: "login",
          query: { redirect: this.$route.fullPath },
        });
      } catch (error) {
        console.error("退出登录失败：", error);
      }
    },
    toRegister() {
      this.$router.push("/register");
    },
    toForget() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.inputbox {
  margin: 1em 1.8rem;
}

::v-deep .dropdown-toggle,
.dropdown-toggle:hover,
.btn-info {
  padding: 0;
  margin-left: 6px;
  font-size: 14px;
  border-bottom: 0.4rem;
}
.btn-group {
  line-height: 14px;
}

.mylogin ::v-deep .dropdown-menu.show {
  width: 370px;
}

::v-deep .form-group {
  margin-top: 1rem;
}

::v-deep .custom-control-label {
  // margin-top:1rem;
  margin: 0 0.6rem;
}

::v-deep .dropdown-toggle,
::v-deep .dropdown-toggle:hover,
::v-deep .btn-info {
  background-color: transparent;
  color: inherit;
  /* 保持文本颜色与父元素一致，或者指定一个具体的颜色 */
  border: none;
}

/* 可选：针对视口单位的自适应布局，在现代浏览器中支持良好 */
@media (min-width: 1200px) {
  .header,
  .navlist {
    width: 1140px;
  }
}

@media (min-width: 1400px) {
  .header,
  .navlist {
    width: 1320px;
  }
}

.register {
  border: solid 1px var(--el-border-color);
  border-radius: 5px;
}

.login .feedback,
.register .feedback {
  display: block;
  color: #9e9e9e;
  width: 100%;
  margin-top: 0.25rem;

  margin-bottom: 0px;
  text-align: left;
}

.form-check {
  min-height: 0;
  margin-bottom: 0;
}

.myAccount ul {
  color: var(--bs-black);
  .dropdown-item {
    color: var(--bs-black);
  }
}
.dropdown-menu.show {
  li {
    margin-bottom: 4px;
    font-size: 0.85rem;
  }
}
a.dropdown-item {
  padding: 8px 0;
}
.btn {
  padding: 2px 10px;
}
</style>
